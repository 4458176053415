import {
  type AxiosRequestConfig,
  type CancelToken,
  type CancelTokenSource,
} from 'axios'
import axios from '@/plugins/axios'
import { handleError } from '@/utils/error-handling'
import type { Meta } from '@/services/base-service'
import { useDefaultStore, useLoadingStore } from '@/store'

/**
 * Base class for all api services. This class should be extended by all api services.
 */
export class ApiService {
  private cancelTokens: Map<string, CancelTokenSource>

  constructor() {
    this.cancelTokens = new Map()
  }

  private createCancelToken(key: string): CancelTokenSource {
    const source = axios.CancelToken.source()
    this.cancelTokens.set(key, source)
    return source
  }

  private clearCancelToken(key: string): void {
    this.cancelTokens.delete(key)
  }

  protected async makeRequest<T>(
    key: string,
    callback: (config: { cancelToken?: CancelToken }) => Promise<any>,
    params?: {
      config?: AxiosRequestConfig
      params?: any
      cancellable?: boolean
      collection: true
      meta?: true
      saving?: boolean
      loading?: string
    }
  ): Promise<{ data: T[]; meta: Meta }>
  protected async makeRequest<T>(
    key: string,
    callback: (config: { cancelToken?: CancelToken }) => Promise<any>,
    params?: {
      config?: AxiosRequestConfig
      params?: any
      cancellable?: boolean
      collection: true
      meta: false
      saving?: boolean
      loading?: string
    }
  ): Promise<T[]>
  protected async makeRequest<T>(
    key: string,
    callback: (config: { cancelToken?: CancelToken }) => Promise<any>,
    params?: {
      config?: AxiosRequestConfig
      params?: any
      cancellable?: boolean
      collection?: false
      saving?: boolean
      loading?: string
    }
  ): Promise<T>
  /**
   * All api requests should go through this function.
   * It handles the request, cancellation, error handling and loading state.
   *
   * @param key The key to identify the request. This is used to cancel the request.
   * @param callback The function that is called to make the axios request.
   * @param options Options for the request.
   * @param options.collection If the request is a collection or not. If true, the response will be returned as { data: T[], meta: Meta }
   * @param options.cancellable If the request should be cancellable. If true, the request can be canceled by calling cancelRequest with the key.
   * @param options.saving If set to true, the global loading state will be set to true and will show a saving... in the navbar while the request is being made.
   * @param options.meta If set to false, the meta object will not be returned in the response. Can only be used if collection is true. If false will return the collection directly.
   * @param options.loading Sets the loading state for a specific key to true in the loading store
   * @returns
   */
  protected async makeRequest<T>(
    key: string,
    callback: (config: { cancelToken?: CancelToken }) => Promise<any>,
    {
      collection = false,
      cancellable = false,
      saving = false,
      meta = true,
      loading,
    }: {
      collection?: boolean
      cancellable?: boolean
      saving?: boolean
      meta?: boolean
      loading?: string
    } = {}
  ) {
    if (saving) {
      useDefaultStore().isSaving(true)
    }

    let cancelTokenSource: CancelTokenSource | undefined
    if (cancellable) {
      if (this.cancelTokens.has(key)) {
        this.cancelRequest(key)
      }

      cancelTokenSource = this.createCancelToken(key)
    }

    if (loading) {
      useLoadingStore().loading({ [loading]: true })
    }

    try {
      const response = await callback({ cancelToken: cancelTokenSource?.token })

      if (cancellable) {
        this.clearCancelToken(key)
      }

      if (collection) {
        if (
          typeof response === 'object' &&
          response.data &&
          Array.isArray(response.data)
        ) {
          if (meta) {
            return response as { data: T[]; meta: Meta }
          }
          return response.data as T[]
        }

        return response as T[]
      }

      if (
        typeof response === 'object' &&
        typeof response.data === 'object' &&
        Object.keys(response).length === 1
      ) {
        return response.data as T
      }

      return response as T
    } catch (e) {
      if (
        cancellable &&
        (e.message !== 'canceled' || e.name !== 'CanceledError')
      ) {
        this.clearCancelToken(key)
      }

      handleError(e)

      throw e
    } finally {
      if (saving) {
        useDefaultStore().isSaving(false)
      }
      if (loading) {
        useLoadingStore().loading({ [loading]: false })
      }
    }
  }

  /**
   * Make an optimistic update to an object and revert it if the request fails.
   * Useful for updating an object in the UI instantly and reverting it if the request fails.
   * Should be used in combination with makeRequest.
   *
   * @param original The original object to update
   * @param data The data to update the object with. This will be merged with the original object.
   * @param callback The callback where the request should be made. If this function throws an error, the original object will be reverted.
   */
  protected async optimisticUpdate<
    TOriginal extends Record<string, any>,
    TData extends Partial<TOriginal>,
  >(
    original: TOriginal,
    data: TData,
    callback: (id: string | number, data: TData) => Promise<any>,
    {
      id = 'id',
    }: {
      id?: keyof TOriginal
    } = {}
  ): Promise<TOriginal> {
    const originalClone = { ...original }

    try {
      Object.assign(original, data)
      const response = await callback(original[id], data)

      return response?.data ?? (response as TOriginal)
    } catch (e) {
      Object.assign(original, originalClone)

      throw e
    }
  }

  /**
   * Cancel a request by key
   */
  public cancelRequest(key: string): void {
    const source = this.cancelTokens.get(key)
    if (source) {
      source.cancel(`Request with key: ${key} has been canceled.`)
      this.clearCancelToken(key)
    }
  }
}
