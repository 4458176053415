<template>
  <slot name="activator" v-bind="{ on }"></slot>
  <Teleport to="body">
    <Transition name="dialog">
      <div
        v-if="internalValue"
        class="fixed inset-0 z-30 flex items-center justify-center bg-black/30"
        @click.self="hide"
      >
        <slot ></slot>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { toRef } from 'vue'
import { useInternalValue } from '@/composables/useInternalValue'
import { watchDialogModelValue } from '@/composables/watchDialogModelValue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: undefined,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue'])
const { internalValue } = useInternalValue(
  toRef(props, 'modelValue'),
  (value) => emit('update:modelValue', value)
)

const on = {
  click: () => (internalValue.value = true),
}

function hide() {
  if (props.persistent) return
  internalValue.value = false
}

watchDialogModelValue(internalValue, {
  onOpen() {
    document.body.style.overflow = 'hidden'
  },
  onClose() {
    document.body.style.overflow = ''
  },
})
</script>

<style lang="scss">
.dialog-enter-active,
.dialog-leave-active {
  transition-duration: 0.3s;

  > * {
    transition: all 0.3s;
  }
}
.dialog-enter-from,
.dialog-leave-to {
  @apply bg-black/0;

  > * {
    @apply translate-y-[100vh] sm:translate-y-0 sm:scale-50 sm:opacity-0;
  }
}
</style>
