export function watchDialogModelValue(
  modelValue: Ref<boolean>,
  {
    onOpen,
    onClose,
    onToggle,
  }: {
    onOpen?: () => void
    onClose?: () => void
    onToggle?: () => void
  }
) {
  onMounted(() => {
    // when value is true on component mount is should also trigger onOpen callback
    if (modelValue.value) {
      onOpen?.()
    }
  })

  watch(modelValue, (value) => {
    onToggle?.()

    if (value) {
      onOpen?.()
    } else {
      onClose?.()
    }
  })

  onBeforeUnmount(() => {
    onClose?.()
  })
}
