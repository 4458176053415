import moment from 'moment/dist/moment'
import 'moment/dist/locale/de'
import 'moment/dist/locale/de-ch'
import 'moment/dist/locale/ro'
import 'moment/dist/locale/fr'
import { getLocale } from '@/plugins/locale'

moment.updateLocale(getLocale(), {
  week: {
    dow: 1,
  },
})

export default moment
