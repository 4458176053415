import axios from '@/plugins/axios'

import { createSavingCall, loadingCall } from './base-service'

export const getAbsences = (params: Record<string, any> = {}) =>
  loadingCall<Domain.Users.DTO.AbsenceData>('absences', () =>
    axios.$post('absences/search', params)
  )

export const createAbsence = createSavingCall((data) =>
  axios.$post(`absences`, data)
)

export const updateAbsence = createSavingCall((id, data) =>
  axios.$put(`absences/${id}`, data)
)
