import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

import { getLocale } from './locale'
import axios from './axios'
import de from '../../lang/de.json'
import en from '../../lang/en.json'
import fr from '../../lang/fr.json'
import ro from '../../lang/ro.json'

export const SUPPORT_LOCALES = ['en', 'de', 'fr', 'ro']

function useKeysAsTranslation(keys, prefix = '') {
  return Object.keys(keys).reduce((acc, key) => {
    if (typeof keys[key] === 'object') {
      acc[key] = useKeysAsTranslation(
        keys[key],
        prefix ? `${prefix}.${key}` : key
      )
    } else {
      acc[key] = prefix ? `${prefix}.${key}` : key
    }
    return acc
  }, {})
}

export const i18n = ref<null | any>(null)

export const t = (key, props: any[] = []) => {
  if (!i18n.value) {
    setupI18n()
  }

  return i18n.value?.global.t(key, props)
}

export const getI18n = () => {
  return i18n
}

export function setupI18n() {
  const i18nInstance = createI18n({
    fallbackLocale: 'de',
    legacy: false,
    locale: getLocale(),
    messages: {
      de,
      en,
      fr,
      ro,
      translations: useKeysAsTranslation(de),
    },
  })
  i18n.value = i18nInstance
  // loadLocaleMessages(i18n, getLocale())

  if (getLocale() !== 'de') {
    // always load German messages for fallback
    // loadLocaleMessages(i18n, 'de')
  }

  return i18nInstance
}

export async function setI18nLanguage(locale) {
  // if (!i18n.global.availableLocales.includes(locale)) {
  //   await loadLocaleMessages(i18n, locale)
  // }
  if (!i18n.value) return

  if (i18n.value.mode === 'legacy') {
    i18n.value.global.locale = locale
  } else {
    i18n.value.global.locale = locale
  }

  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html')?.setAttribute('lang', locale)

  // use custom Prefer-Language header for API requests because Accept-Language is overwritten by the browser
  axios.defaults.headers.common['Prefer-Language'] = locale
}

export async function loadLocaleMessages(i18n, locale) {
  if (locale === 'translations') {
    const messages = await import('../../lang/de.json')
    i18n.value.global.setLocaleMessage(
      locale,
      useKeysAsTranslation(messages.default)
    )
    return nextTick()
  }

  // load locale messages with dynamic import
  const messages = await import(`../../lang/${locale}.json`)

  // set locale and locale message
  i18n.value.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}
