export const routes = [
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../Views/Settings.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/warning-categoires',
    name: 'WarningCategories',
    component: () => import('../Views/WarningCategories.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/document-types',
    name: 'DocumentTypes',
    component: () => import('../Views/User/DocumentTypes.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/languages',
    name: 'LanguageSettings',
    component: () => import('../Views/Settings/LanguageSettings.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/dashboard',
    name: 'DashboardSettings',
    component: () => import('../Views/Settings/DashboardSettings.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/cultures',
    name: 'Cultures',
    component: () => import('../Views/Settings/Cultures/Cultures.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/create-culture',
    name: 'CreateCulture',
    component: () => import('../Views/Settings/Cultures/CreateCulture.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/cultures/:id',
    name: 'EditCulture',
    component: () => import('../Views/Settings/Cultures/EditCulture.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/activities',
    name: 'Activities',
    component: () => import('../Views/Settings/Activities/Activities.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/create-activity',
    name: 'CreateActivity',
    component: () => import('../Views/Settings/Activities/CreateActivity.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/activities/:id',
    name: 'EditActivity',
    component: () => import('../Views/Settings/Activities/EditActivity.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/companies',
    name: 'Companies',
    component: () => import('../Views/Settings/Companies/Companies.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/create-company',
    name: 'CreateCompany',
    component: () => import('../Views/Settings/Companies/CreateCompany.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/companies/:id',
    name: 'EditCompany',
    component: () => import('../Views/Settings/Companies/EditCompany.vue'),
    meta: { auth: true },
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('../Views/Authorization/Roles.vue'),
    meta: { auth: true },
  },
  {
    path: '/roles/:id',
    name: 'ShowRole',
    component: () => import('../Views/Authorization/ShowRole.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/translations',
    name: 'ManageTranslations',
    component: () => import('../Views/Settings/ManageTranslations.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/tags',
    name: 'ManageTags',
    component: () => import('../Views/Settings/ManageTags.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/notifications',
    name: 'ManageNotifications',
    component: () => import('../Views/Settings/ManageNotifications.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/notification-layouts',
    name: 'ManageNotificationLayouts',
    component: () => import('@/Views/Settings/ManageNotificationLayouts.vue'),
    meta: { auth: true },
  },
]
