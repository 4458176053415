import axios from 'axios'
import moment from 'moment'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'

export const useBedStore = defineStore('bed', {
  state: () => ({
    beds: [],
  }),
  actions: {
    addBed(bed) {
      this.beds.push(bed)
    },
    updateBed(updatedBed) {
      const bed = this.beds.find((b) => b.id === updatedBed.id)
      const index = this.beds.indexOf(bed)
      this.beds[index] = updatedBed
    },
    deleteBed(bedId) {
      const bed = this.beds.find((b) => b.id === bedId)
      const index = this.beds.indexOf(bed)
      bed.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      this.beds[index] = bed
    },

    fetchBeds() {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ beds: true })
        axios
          .get('/beds?all=true')
          .then((response) => {
            this.beds = response.data
            resolve(this.beds)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Betten.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ beds: false })
          })
      })
    },
    updateBed(bed) {
      return new Promise((resolve, reject) => {
        axios
          .put(`beds/${bed.id}`, bed)
          .then(() => {
            this.updateBed(bed)
            resolve(bed)
          })
          .catch((error) => reject(error))
      })
    },
    deleteBed(bedId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`beds/${bedId}`)
          .then(() => {
            this.fetchBeds()
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
  },
})
