import { ref, watch, type Ref } from 'vue'
import isEqual from 'lodash/isEqual'

export function useInternalValue<T>(
  modelValue: Ref<T>,
  onChange: (newValue: T, oldValue: T) => void | false,
  { defaultValue = undefined }: { defaultValue?: T } = {}
) {
  const internalValue = ref(
    modelValue.value === undefined ? defaultValue : modelValue.value
  ) as Ref<T>

  watch(modelValue, (value) => {
    internalValue.value = value
  })

  // when the modelValue is undefined, we use the internalValue,
  // but if a modelValue is provided, we dont store the value internally
  const internalComputedValue = computed({
    get() {
      return internalValue.value
    },
    set(newValue: T) {
      const oldValue = internalValue.value
      if (newValue === oldValue) {
        return
      }
      if (
        typeof newValue === 'object' &&
        typeof oldValue === 'object' &&
        isEqual(newValue, oldValue)
      ) {
        return
      }

      if (onChange) {
        const returnValue = onChange?.(newValue, oldValue)
        if (returnValue === false) return
      }

      internalValue.value = newValue
    },
  })

  return {
    internalValue: internalComputedValue,
  }
}
