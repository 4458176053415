import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/roomdispositioner/reservationen',
    name: 'Roomdispositioner',
    component: () => import('../Views/Roomdispositioner/Dashboard.vue'),
    meta: { auth: true },
  },
  {
    path: '/roomdispositioner/evaluation',
    name: 'RoomdispositionerEvaluation',
    component: () =>
      import('../Views/Roomdispositioner/RoomdispositionerEvaluation.vue'),
    meta: { auth: true },
  },
  {
    path: '/roomdispositioner/room-overview',
    name: 'RoomOverview',
    component: () => import('../Views/Roomdispositioner/RoomOverview.vue'),
    meta: { auth: true },
  },
  {
    path: '/roomdispositioner/show-room',
    name: 'RoomOccupancy',
    component: () => import('../Views/Roomdispositioner/RoomOccupancy.vue'),
    meta: { auth: true },
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: () => import('../Views/Roomdispositioner/Room/Rooms.vue'),
    meta: { auth: true },
  },
  {
    path: '/houses',
    name: 'Houses',
    component: () => import('../Views/Roomdispositioner/House/Houses.vue'),
    meta: { auth: true },
  },
  {
    path: '/houses/:id',
    name: 'ShowHouse',
    component: () => import('../Views/Roomdispositioner/House/ShowHouse.vue'),
    meta: { auth: true },
  },
  {
    path: '/rooms/add',
    name: 'AddRoom',
    component: () => import('../Views/Roomdispositioner/Room/AddRoom.vue'),
    meta: { auth: true },
  },
  {
    path: '/rooms/:id',
    name: 'ShowRoom',
    component: () => import('../Views/Roomdispositioner/Room/ShowRoom.vue'),
    meta: { auth: true },
  },
  {
    path: '/inventars',
    name: 'Inventars',
    component: () =>
      import('../Views/Roomdispositioner/Inventar/Inventars.vue'),
    meta: { auth: true },
  },
  {
    path: '/inventars/:id',
    name: 'ShowInventar',
    component: () =>
      import('../Views/Roomdispositioner/Inventar/ShowInventar.vue'),
    meta: { auth: true },
  },
  {
    path: '/beds',
    name: 'Beds',
    component: () => import('../Views/Roomdispositioner/Bed/Beds.vue'),
    meta: { auth: true },
  },
  {
    path: '/beds/:id',
    name: 'ShowBed',
    component: () => import('../Views/Roomdispositioner/Bed/ShowBed.vue'),
    meta: { auth: true },
  },
]
