// export type VuetifyForm = {
//   errors: Record<string, string>
//   invalid: () => boolean
//   validate: (name?: string | null) => boolean
//   clearErrors: () => void
//   resetValidation: () => void
//   setErrors: (errors: Record<string, [string]>) => void
//   reset: () => void
//   registerField: (field: {
//     value: any
//     rules: Array<(value: any) => boolean | string> | null | undefined
//     error: string | null | Ref<string | null> | undefined
//     name?: string | null
//   }) => string
//   unregisterField: (name: string) => void
// }

export function useClientValidationForm() {
  type Field = {
    name: string
    value: Ref<any>
    rules: Array<(value: any) => boolean | string>
    error: Ref<string | null>
  }

  const fields = ref([] as Array<Field>)

  const form = {
    errors: {},
    invalid: () => false,
    validate: (name: string | null = null) => {
      const valid = fields.value
        .filter((field) => !name || name === field.name)
        .map((field) => {
          if (!field.rules) return true
          const error = field.rules
            .map((rule) => rule(field.value))
            .find((result) => result !== true)
          field.error = typeof error === 'string' ? error : null
          return !field.error
        })

      const isValid = valid.every((result) => result === true)

      return isValid
    },
    clearErrors: () => {
      fields.value.forEach((field) => {
        field.error = null
      })
    },
    resetValidation: () => {
      form.clearErrors()
      form.reset()
    },
    setErrors: (errors: { [key: string]: [string] }) => {
      Object.entries(errors).forEach(([key, value]) => {
        const field = fields.value.find((field) => field.name === key)
        if (field) field.error = value[0]
      })
    },
    reset: () => {
      fields.value.forEach((field) => {
        field.error = null
        field.value = ''
      })
    },
    registerField: ({ value, rules, error, name }) => {
      if (!name) {
        name = Math.random().toString(36).substring(7)
      }
      // vue unwrapps the refs, so the type is wrong
      fields.value.push({
        value,
        rules: rules ?? [],
        error: (error ?? null) as any,
        name,
      })

      return name
    },
    unregisterField: (name) => {
      const index = fields.value.findIndex((field) => field.name === name)
      fields.value.splice(index, 1)
    },
  }

  provide('vuetifyForm', form)

  return { form }
}

export type VuetifyForm = ReturnType<typeof useClientValidationForm>['form']
