import axios from '@/plugins/axios'
import moment from 'moment'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'

export const useRoomStore = defineStore('room', {
  state: () => ({
    allRooms: [],
  }),
  getters: {
    rooms: (state) => state.allRooms.filter((r) => !r.deleted_at),
    activeRooms: (state) =>
      state.allRooms.filter((r) => !r.deleted_at && r.is_active),
    deletedRooms: (state) => state.allRooms.filter((r) => r.deleted_at),
  },
  actions: {
    setRooms(rooms) {
      rooms.sort((a, b) => a.number - b.number)
      this.allRooms = rooms
    },
    adddRoom(room) {
      this.allRooms.push(room)
    },
    removeRoom(roomId) {
      const room = this.allRooms.find((r) => r.id === roomId)
      const index = this.allRooms.indexOf(room)
      room.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      this.allRooms[index] = room
    },
    fetchRooms() {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ rooms: true })
        axios
          .get('/rooms?all=true')
          .then((response) => {
            this.setRooms(response.data)
            resolve(this.rooms)
          })
          .catch((error) => {
            useDefaultStore().alert({
              text: 'Fehler beim Laden der Räume.',
              type: 'error',
            })
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ rooms: false })
          })
      })
    },
    updateRoom({}, room) {
      return new Promise((resolve, reject) => {
        axios
          .put(`rooms/${room.id}`, room)
          .then(() => {
            const room = this.allRooms.find((r) => r.id === updatedRoom.id)
            const index = this.allRooms.indexOf(room)
            this.allRooms[index] = updatedRoom
            resolve(room)
          })
          .catch((error) => reject(error))
      })
    },
    deleteRoom(roomId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`rooms/${roomId}`)
          .then(() => {
            this.removeRoom(roomId)
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
  },
})
