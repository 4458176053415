<template>
  <div class="home"></div>
</template>

<script>
import { UserType } from '@/utils'
import { useRunSheetStore } from '@/store/runSheets'

export default {
  mounted() {
    if (!this.$auth.user().is_password_changed)
      this.$router.push('/profile/change-password')
    else if (this.$auth.user().type === UserType.Customer) {
      this.$router.push('/kundenportal')
    } else if (this.$auth.user().type === 1)
      this.$router.push('/kundenportal/home')
    else if (
      this.$auth.user().hasPermission(['superadmin'], ['dashboard_read'])
    )
      this.$router.push('/dashboard')
    else if (this.$auth.user().hasPermission([], ['timerecord_read_write']))
      this.$router.push('/time')
    else if (this.$auth.user().hasPermission([], ['rapport_read']))
      this.$router.push('/rapport')
    else if (
      this.$auth
        .user()
        .hasPermission([], ['employee_preview_read', 'employee_read'])
    )
      this.$router.push('/employee')
    else if (this.$auth.user().hasPermission([], ['roomdispositioner_read']))
      this.$router.push('/roomdispositioner')
    else if (this.$auth.user().hasPermission([], ['hourrecord_read']))
      this.$router.push('/hourrecords')
    else if (this.$auth.user().hasPermission([], ['worker_read']))
      this.$router.push('/worker')
    else if (this.$auth.user().hasPermission([], ['customer_read']))
      this.$router.push('/customer')
    else if (
      this.$auth
        .user()
        .hasPermission(
          [],
          ['timerecord_stats', 'evaluation_customer', 'evaluation_employee']
        )
    ) {
      this.$router.push('/evaluation')
    } else {
      this.loadRunSheets()
    }
  },

  methods: {
    loadRunSheets() {
      const runSheetStore = useRunSheetStore()

      runSheetStore.fetchRunSheetTemplates().then((runSheets) => {
        if (runSheets.length > 0) {
          this.$router.push(`/forms/runsheet/${runSheets[0].slug}`)
        }
      })
    },
  },
}
</script>
