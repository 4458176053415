import { useStoreMultipleStore as storeMultipleStore } from '@/store/store-multiple'

export default {
  methods: {
    async $storeMultiple({
      items,
      callback,
      errors = [],
      itemText = (item) => item.name || item.text || item.title || item.label,
      disableSucceeded = false,
      showEveryItemStatusFrom = 2,
      finished = () => {},
    }) {
      storeMultipleStore().toStoreOptions = {
        disableSucceeded,
        showEveryItemStatusFrom,
        finishedCallback: finished,
      }
      storeMultipleStore().itemTextFunction = itemText
      storeMultipleStore().setToStoreItems(items)

      for (let i = 0; i < items.length; i++) {
        try {
          let hasError = false
          await callback(items[i], i, (message) => {
            storeMultipleStore().setToStoreStatus({
              item: items[i],
              status: {
                key: 'error',
                message,
              },
            })
            hasError = true
          })
          if (hasError) {
            continue
          }
          storeMultipleStore().setToStoreStatus({
            item: items[i],
            status: {
              key: 'success',
            },
          })
        } catch (error) {
          const errorMessage = errors.find((e) => {
            const includes = Array.isArray(e.includes)
              ? e.includes
              : [e.includes]
            return error.includes(...includes)
          })
          if (errorMessage) {
            storeMultipleStore().setToStoreStatus({
              item: items[i],
              status: {
                key: 'error',
                message: errorMessage.message,
              },
            })
          } else {
            storeMultipleStore().setToStoreStatus({
              item: items[i],
              status: {
                key: 'error',
                message: this.$t('general.unknown-error'),
              },
            })
          }
        }
      }

      const options = storeMultipleStore().toStoreOptions
      const { toStore } = storeMultipleStore()

      if (
        storeMultipleStore().toStoreFinished &&
        options.showEveryItemStatusFrom > toStore.length
      ) {
        if (storeMultipleStore().toStoreSucceeded) {
          if (!options.disableSucceeded) {
            this.$success(
              this.$t('general.store-multiple-dialog.successfully-saved')
            )
          }
          options.finishedCallback()
        } else if (toStore.length > 1) {
          this.$error(
            this.$t('general.store-multiple-dialog.error-while-saving')
          )
          options.finishedCallback()
        } else {
          this.$swal(
            this.$t('general.error'),
            toStore[0].status.message,
            'error'
          )
        }
      } else if (
        storeMultipleStore().toStoreFinished &&
        options.disableSucceeded
      ) {
        options.finishedCallback()
      }
    },
  },
}
