import { computed, onUnmounted, ref, type ComputedRef } from 'vue'

type Breakpoints = {
  xs: ComputedRef<boolean>
  sm: ComputedRef<boolean>
  md: ComputedRef<boolean>
  lg: ComputedRef<boolean>
  xl: ComputedRef<boolean>
  xxl: ComputedRef<boolean>
}

export function useBreakPoints(): Breakpoints {
  const breakpointsWidths = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  }
  const windowWidth = ref(window.innerWidth)

  const xs = computed(() => windowWidth.value > breakpointsWidths.xs)
  const sm = computed(() => windowWidth.value > breakpointsWidths.sm)
  const md = computed(() => windowWidth.value > breakpointsWidths.md)
  const lg = computed(() => windowWidth.value > breakpointsWidths.lg)
  const xl = computed(() => windowWidth.value > breakpointsWidths.xl)
  const xxl = computed(() => windowWidth.value > breakpointsWidths.xxl)

  function updateWindowWidth() {
    windowWidth.value = window.innerWidth
  }

  onMounted(() => {
    window.addEventListener('resize', updateWindowWidth)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateWindowWidth)
  })

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  }
}
