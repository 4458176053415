<template>
  <Dialog
    v-if="
      toStore.length && toStoreOptions.showEveryItemStatusFrom <= toStore.length
    "
    :model-value="!!toStore.length"
    :persistent="!toStoreFinished"
    @update:model-value="close"
  >
    <CardLayout
      :title="
        toStoreFinished
          ? $t('general.store-multiple-dialog.title')
          : $t('general.store-multiple-dialog.saving')
      "
    >
      <div class="w-[400px]">
        <div class="card-content">
          <div
            v-for="({ item, status }, index) in toStore"
            :key="index"
            class="flex justify-between gap-4"
          >
            <p>{{ itemTextFunction(item) }}</p>

            <div>
              <LoadingDots
                v-if="status.key === 'loading'"
                :size="16"
                :width="2"
                indeterminate
              />
              <AppIcon
                v-else-if="status.key === 'success'"
                class="text-theme-primary"
              >
                check
              </AppIcon>
              <Tooltip v-else-if="status.key === 'error'" top>
                <template v-slot:activator="{ on }">
                  <AppIcon class="cursor-pointer text-red-500" v-on="on">
                    error
                  </AppIcon>
                </template>

                <span>{{ status.message }}</span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <template v-slot:actions>
        <template v-if="toStoreFinished">
          <AppButton
            v-if="!toStoreSucceeded"
            text
            color="black"
            @click="goBack"
          >
            {{ $t('general.store-multiple-dialog.go-back') }}
          </AppButton>
          <AppButton
            :disabled="!toStoreFinished"
            class="ml-auto"
            @click="close"
          >
            <span v-if="toStoreSucceeded">
              {{ $t('general.store-multiple-dialog.finished') }}
            </span>
            <span v-else>
              {{ $t('general.store-multiple-dialog.close') }}
            </span>
          </AppButton>
        </template>
      </template>
    </CardLayout>
  </Dialog>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useStoreMultipleStore } from '@/store/store-multiple'

export default {
  computed: {
    ...mapState(useStoreMultipleStore, [
      'itemTextFunction',
      'toStoreOptions',
      'toStoreFinished',
      'toStoreSucceeded',
    ]),
    ...mapWritableState(useStoreMultipleStore, ['toStore']),

    allFailed() {
      return this.toStore.every((item) => item.status.key === 'error')
    },
  },

  methods: {
    close() {
      this.toStoreOptions.finishedCallback()
      this.toStore = []
    },

    goBack() {
      this.toStore = []
    },
  },
}
</script>

<style lang="scss" scoped>
.card-content {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
}
</style>
