<template>
  <slot
    name="activator"
    v-bind="{
      on: {
        hover: show,
        mouseenter: show,
        mouseleave: hide,
        focus: show,
        blur: hide,
      },
    }"
  ></slot>
  <Teleport to="body">
    <div
      v-if="showTooltip"
      ref="tooltip"
      class="absolute z-30 max-w-[calc(100vw-20px)] rounded bg-gray-600/80 px-3 py-1 text-white"
      :style="style"
    >
      <slot></slot>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import type { CSSProperties } from 'vue'
import { pageRect, positioningRelativeTo } from '@/utils/positioning'

const props = defineProps<{
  bottom?: boolean
  left?: boolean
  right?: boolean
  top?: boolean
}>()

const showTooltip = ref(false)
const style = ref<CSSProperties>({
  top: `0px`,
  left: `0px`,
  visibility: 'hidden',
})
const tooltip = ref<HTMLDivElement | null>(null)

function show(event: MouseEvent | FocusEvent) {
  showTooltip.value = true
  nextTick(() => {
    if (!tooltip.value) return

    const hoverRect = pageRect(event.target as HTMLElement)
    const tooltipRect = pageRect(tooltip.value)

    let orientation: 'top' | 'bottom' | 'left' | 'right'
    if (props.bottom) {
      orientation = 'bottom'
    } else if (props.left) {
      orientation = 'left'
    } else if (props.right) {
      orientation = 'right'
    } else {
      orientation = 'top'
    }

    const position = positioningRelativeTo(orientation, hoverRect, tooltipRect)

    style.value = {
      top: `${position.top}px`,
      left: `${position.left}px`,
      visibility: 'visible',
    }
  })
}

function hide() {
  showTooltip.value = false
}
</script>
