import { useLoadingStore, useDefaultStore } from '@/store'
import { handleError, type ErrorHandlers } from '@/utils/error-handling'

function parseErrorMessage(errorMessage) {
  if (!errorMessage) return null
  if (typeof errorMessage === 'function') return errorMessage()
  if (typeof errorMessage === 'string') return errorMessage
  return null
}

function makeApiResponse(data) {
  if (data instanceof ApiResponse) return data
  if (data instanceof Object && data.data && Array.isArray(data.data)) {
    return ApiResponse.create(data.data, data.meta)
  }
  if (data instanceof Object && data.data) {
    return data.data
  }
  return data
}

function handleApiError(e, errorMessage) {
  if (e instanceof HandledError === false) {
    if (errorMessage) {
      useDefaultStore().error(errorMessage)
    } else {
      handleError(e)
    }
  }
  throw e
}

export function createApiCall(callback, { errorMessage = null } = {}) {
  return async (...args) => {
    try {
      const data = await callback(...args)

      return data?.data ?? data
    } catch (e) {
      handleApiError(e, parseErrorMessage(errorMessage))
    }
  }
}

function apiResponse(response: any) {
  if (
    typeof response === 'object' &&
    response.data &&
    Array.isArray(response.data)
  ) {
    return { data: response.data, meta: response.meta }
  }

  return { data: response, meta: null }
}

export type Meta = {
  current_page: number
  from: number
  last_page: number
  per_page: number
  to: number
  total: number
}

export async function apiCall<TResponse>(
  callback: () => Promise<any>,
  {
    errorMessage = null,
  }: {
    errorMessage?: (() => string) | string | null
  } = {}
) {
  try {
    const data = await callback()

    if (
      typeof data === 'object' &&
      typeof data.data === 'object' &&
      !Array.isArray(data.data)
    ) {
      return data.data as TResponse
    }

    return data as TResponse
  } catch (e) {
    handleApiError(e, parseErrorMessage(errorMessage))

    throw e
  }
}

export function loadingCall<TResponse>(
  loading: string,
  callback: () => Promise<any>,
  options?: {
    errorMessage?: (() => string) | string | null
    collection?: true
    withoutLoading?: boolean
  }
): Promise<{ data: TResponse[]; meta: Meta | null }>
export function loadingCall<TResponse>(
  loading: string,
  callback: () => Promise<any>,
  options?: {
    errorMessage?: (() => string) | string | null
    collection?: false
    withoutLoading?: boolean
  }
): Promise<TResponse>

export async function loadingCall<TResponse>(
  loading: string,
  callback: () => Promise<any>,
  {
    errorMessage = null,
    collection = true,
    withoutLoading = false,
  }: {
    errorMessage?: (() => string) | string | null
    collection?: boolean
    withoutLoading?: boolean
  } = {}
) {
  if (!withoutLoading) {
    useLoadingStore().isLoading[loading] = true
  }
  try {
    const data = await callback()

    if (!withoutLoading) {
      useLoadingStore().isLoading[loading] = false
    }

    if (collection) {
      return apiResponse(data) as { data: TResponse[]; meta: Meta | null }
    }

    return data as TResponse
  } catch (e) {
    if (!withoutLoading) {
      useLoadingStore().isLoading[loading] = false
    }

    handleApiError(e, parseErrorMessage(errorMessage))

    throw e
  }
}

export function createLoadingCall(
  loading,
  callback,
  { errorMessage = null } = {}
) {
  return async (...args) => {
    const response = await loadingCall(loading, () => callback(...args), {
      errorMessage,
    })
    return makeApiResponse(response)
  }
}

export async function savingCall<TResponse>(
  callback: () => Promise<any>,
  {
    errorMessage = null,
    onSuccess = null,
  }: {
    errorMessage?: (() => string) | string | null
    onSuccess?: ((response: TResponse) => void) | null
  } = {}
): Promise<TResponse> {
  useDefaultStore().isSaving(true)
  try {
    const data = await callback()

    useDefaultStore().isSaving(false)

    const response = (data?.data ?? data) as TResponse
    onSuccess?.(response)

    return response
  } catch (e) {
    useDefaultStore().isSaving(false)

    handleApiError(e, parseErrorMessage(errorMessage))

    throw e
  }
}

export function createSavingCall(
  callback,
  {
    errorMessage = null,
  }: {
    errorMessage?: (() => string) | string | null
  } = {}
) {
  return (...args) => savingCall(() => callback(...args), { errorMessage })
}

export async function optimisticUpdateCall<
  TOriginal extends Record<string, any>,
  TData extends Partial<TOriginal>,
>(
  original: TOriginal,
  data: TData,
  callback: (id: string | number, data: TData) => Promise<any>,
  {
    errorHandlers = {},
    errorFallback = null,
    id = 'id',
  }: {
    id?: keyof TOriginal
    errorHandlers?: ErrorHandlers
    errorFallback?: (() => string) | null
  } = {}
): Promise<TOriginal> {
  useDefaultStore().isSaving(true)

  const originalClone = { ...original }
  try {
    Object.assign(original, data)
    const response = await callback(original[id], data)

    useDefaultStore().isSaving(false)

    return response?.data ?? (response as TOriginal)
  } catch (e) {
    Object.assign(original, originalClone)
    useDefaultStore().isSaving(false)
    handleError(e, errorHandlers, errorFallback)

    throw e
  }
}

// needed for objects that extends Array to work with vue
export function makeReactive(obj) {
  const proto = obj.__proto__

  Object.defineProperty(obj, '__proto__', {
    get() {
      return proto
    },
    set(newValue) {
      proto.__proto__ = newValue
    },
  })
}

export class ApiResponse<T> extends Array<T> {
  _meta = null

  constructor(length?: number)
  constructor(...items: any[])
  constructor(...args: T[]) {
    super(...args)

    makeReactive(this)
  }

  static create<T>(data: T[], meta) {
    const apiResponse = new ApiResponse<T>(...data)
    apiResponse.meta(meta)
    return apiResponse
  }

  meta(data = null) {
    if (data) {
      this._meta = data
    }

    return this._meta
  }

  // built-in methods will use this as the constructor
  static get [Symbol.species](): ArrayConstructor {
    return Array
  }
}

export class HandledError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options)
    this.name = 'HandledError'
  }
}
