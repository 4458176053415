<template>
  <div class="spinner"></div>
</template>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  border-left-color: currentColor;
  animation: spin 1s linear infinite;
}
</style>
