import { type DirectiveBinding } from 'vue'

type Props = {
  top: number
  left: number
  width: number
  disableY?: boolean
}

function ajustPosition(el: HTMLElement, binding: DirectiveBinding<Props>) {
  let { top, left, width } = binding.value
  const { height } = el.getBoundingClientRect()
  const { innerHeight, innerWidth } = window
  let right = left + width
  let bottom = top + height

  if (bottom > innerHeight + window.scrollY - 10 && !binding.value.disableY) {
    bottom = innerHeight + window.scrollY - 10
    top = innerHeight + window.scrollY - height - 10
  }
  if (top < 0) {
    top = 0
  }
  if (left < 0) {
    left = 0
    right = width
  }
  if (right > innerWidth) {
    width = innerWidth - left
  }

  el.style.top = `${top}px`
  el.style.left = `${left}px`
  el.style.width = `${width}px`
}

export const ensureWithinPage = {
  mounted(el: HTMLElement, binding: DirectiveBinding<Props>) {
    ajustPosition(el, binding)
  },

  updated(el: HTMLElement, binding: DirectiveBinding<Props>) {
    ajustPosition(el, binding)
  },
}
