import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/orders',
    name: 'OrdersFieldsMapCombination',
    component: () => import('../Views/Orders/OrderFieldMapCombination.vue'),
    meta: { auth: true },
  },
  {
    path: '/orders/:id',
    name: 'EditOrderAdmin',

    component: () => import('../Views/Orders/EditOrder.vue'),
    meta: { auth: true },
  },
  {
    path: '/orders/create',
    name: 'CreateOrderAdmin',

    component: () => import('../Views/Orders/CreateOrder.vue'),
    meta: { auth: true },
  },
]
