import { useDefaultStore as defaultStore } from '@/store'
import { t } from '@/plugins/i18n'

function error(...args) {
  return defaultStore().error(...args)
}

function alert(...args) {
  return defaultStore().alert(...args)
}

function success(...args) {
  return defaultStore().success(...args)
}

function handleError(err) {
  if (err.response?.data?.error) {
    error(err.response.data.message)
  } else {
    error(t('general.unknown-error'))
  }
}

export function registerAlerts(app) {
  app.config.globalProperties.$error = error
  app.config.globalProperties.$alert = alert
  app.config.globalProperties.$success = success
  app.config.globalProperties.$handleError = handleError
}

export function useAlerts() {
  return {
    error,
    alert,
    success,
    handleError,
  }
}
