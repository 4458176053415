import { createRouter, createWebHistory } from 'vue-router'
import Home from '../Views/Home.vue'
import { useAuthStore } from '../store/auth'
import Posthog from 'posthog-js'
import { eventBus } from '@/utils/eventBus'
import { routes as customerPortalRoutes } from './customerPortalRouter'
import { routes as settingsRoutes } from './settingsRouter'
import { routes as formRoutes } from './formsRouter'
import { routes as resourcePlannerRoutes } from './resourcePlannerRouter'
import { routes as userRoutes } from './userRouter'
import { routes as roomdispositionerRoutes } from './roomdispositionerRouter'
import { routes as orderManagementRoutes } from './orderManagementRouter'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { auth: true },
    },
    {
      path: '/playground',
      name: 'Playground',
      component: () => import('../Views/Playground/Playground.vue'),
      meta: { auth: true },
    },
    {
      path: '/playground/combobox',
      name: 'ComboboxPlayground',
      component: () => import('../Views/Playground/ComboboxPlayground.vue'),
      meta: { auth: true },
    },
    {
      path: '/playground/select',
      name: 'SelectPlayground',
      component: () => import('../Views/Playground/SelectPlayground.vue'),
      meta: { auth: true },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../Views/Auth/Login.vue'),
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('../Views/Auth/ResetPassword.vue'),
    },
    {
      path: '/set-password',
      props: (route) => ({
        token: route.query.token,
        email: route.query.email,
      }),
      name: 'SetPassword',
      component: () => import('../Views/Auth/SetPassword.vue'),
    },
    {
      path: '/',
      component: () => import('../Layouts/PageLayout.vue'),
      children: [
        ...settingsRoutes,
        ...resourcePlannerRoutes,
        ...customerPortalRoutes,
        ...formRoutes,
        ...userRoutes,
        ...roomdispositionerRoutes,
        ...orderManagementRoutes,

        {
          path: '/evaluation',
          name: 'Evaluation',
          component: () => import('../Views/Evaluation/Evaluation.vue'),
          meta: { auth: true },
        },
        {
          path: '/time',
          name: 'Time',
          component: () => import('../Views/Time.vue'),
          meta: { auth: true },
        },
        {
          path: '/profile/change-password',
          name: 'ChangePassword',
          component: () => import('../Views/Profile/ChangePassword.vue'),
          meta: { auth: true },
        },
        {
          path: '/profile/edit',
          name: 'EditProfile',
          component: () => import('../Views/EditProfile.vue'),
          meta: { auth: true },
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('../Views/Dashboard.vue'),
          props: true,
          meta: { auth: true },
        },
        {
          path: '/hourrecords',
          name: 'Hourrecords',
          component: () => import('../Views/Hourrecords/Hourrecords.vue'),
          meta: { auth: true },
        },
        {
          path: '/hourrecords/:year/:week',
          name: 'HourrecordDetails',
          component: () => import('../Views/Hourrecords/HourrecordDetails.vue'),
          props: (route) => ({ edit: route.query.edit === 'true' }),
          meta: { auth: true },
        },
        {
          path: '/release-notes',
          name: 'ReleaseNotes',
          component: () => import('../Views/ReleaseNotes.vue'),
          meta: { auth: true },
        },
        {
          path: '/transaction-types',
          name: 'TransactionTypes',
          component: () => import('../Views/transactions/TransactionTypes.vue'),
          meta: { auth: true },
        },
        {
          path: '/add-transactions',
          name: 'AddTransactions',
          component: () => import('../Views/transactions/AddTransactions.vue'),
          meta: { auth: true },
        },
        {
          path: '/employee-transactions',
          name: 'EmployeeTransactions',
          component: () =>
            import('../Views/transactions/EmployeeTransactions.vue'),
          meta: { auth: true },
        },
        {
          path: '/transactions',
          name: 'Transactions',
          component: () => import('../Views/transactions/Transactions.vue'),
          meta: { auth: true },
        },
        {
          path: '/saldo-overview',
          name: 'SaoldOverview',
          component: () => import('../Views/transactions/SaldoOverview.vue'),
          meta: { auth: true },
        },
        {
          path: '/files',
          name: 'DropboxFiles',
          component: () => import('../Views/Filemanager/DropboxEmbed.vue'),
          meta: { auth: true },
        },
        {
          path: '/filemanager',
          name: 'Filemanager',
          component: () => import('../Views/Filemanager/FileManager.vue'),
          meta: { auth: true },
        },

        {
          path: '/linksammlung',
          name: 'LinkCollection',
          component: () => import('../Views/LinkCollection.vue'),
          meta: { auth: true },
        },
        {
          path: '/projekte',
          name: 'Projects',
          component: () => import('../Views/Projects.vue'),
          meta: { auth: true },
        },

        {
          path: '/manage-faq',
          name: 'ManageFaq',
          component: () => import('../Views/FAQ/ManageFaq.vue'),
          meta: { auth: true },
        },
      ],
    },
    {
      path: '/server-error',
      name: 'ServerError',
      component: () => import('../Views/ErrorPage.vue'),
      props: { code: 500 },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('../Views/ErrorPage.vue'),
      props: { code: 404 },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (from.name === to.name) return {}

    if (!savedPosition) {
      return { top: 0, left: 0 }
    }

    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        eventBus.off('dataLoaded', returnScroll)
        returnScroll()
      }, 500)

      function returnScroll() {
        clearTimeout(timeout)
        resolve(savedPosition || { top: 0, left: 0 })
      }

      eventBus.once('dataLoaded', returnScroll)
    })
  },
})

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore()
  const authUser = authStore.user

  const reqAuth = to.meta.auth
  const loginQuery = { path: '/login', query: { redirect: to.fullPath } }

  if (reqAuth) {
    if (!authUser) {
      await authStore.fetchUser()
    }

    if (reqAuth === 'optional') {
      return next()
    }

    if (!authStore.user) {
      return next(loginQuery)
    }

    if (
      !authStore.user.is_password_changed &&
      to.path !== '/profile/change-password'
    ) {
      return next('/profile/change-password')
    }
  }

  next() // make sure to always call next()!
})

router.afterEach((to, _from, failure) => {
  if (!failure) {
    nextTick(() => {
      Posthog.capture('$pageview', {
        path: to.fullPath,
      })
    })
  }
})

export default router
