<template>
  <form @submit.prevent="submit">
    <slot></slot>
  </form>
</template>

<script setup lang="ts">
import { useClientValidationForm } from '@/composables/useClientValidationForm'

const emit = defineEmits(['submit', 'submitWitoutValidation'])
const { form } = useClientValidationForm()

function submit(event) {
  event.preventDefault()
  emit('submitWitoutValidation', event)
  const valid = form.validate()

  if (!valid) return

  emit('submit', event)
}

defineExpose({
  validate: form.validate,
  reset: form.reset,
  clearErrors: form.clearErrors,
  setErrorrs: form.setErrors,
  resetValidation: form.clearErrors,
})
</script>
