import axios from '@/plugins/axios'
import moment from 'moment'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    allCustomers: [],
  }),
  getters: {
    customers: (state) => state.allCustomers.filter((c) => !c.deleted_at),
    deletedCustomers: (state) => state.allCustomers.filter((c) => c.deleted_at),
  },
  actions: {
    setCustomers(customers) {
      this.allCustomers = [...customers]
    },
    addCustomer(customer) {
      this.allCustomers.push(customer)
    },
    updateCustomer(updatedCustomer) {
      const customer = this.allCustomers.find(
        (c) => c.id === updatedCustomer.id
      )
      const index = this.allCustomers.indexOf(customer)
      this.allCustomers[index] = updatedCustomer
    },
    deleteCustomer(customerId) {
      const customer = this.allCustomers.find((e) => e.id === customerId)
      const index = this.allCustomers.indexOf(customer)
      customer.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      this.allCustomers[index] = customer
    },
    fetchCustomers(params = {}) {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ customers: true })
        axios
          .$get('/customers', {
            params: {
              all: true,
              ...params,
            },
          })
          .then((response) => {
            this.setCustomers(response.data)
            resolve(this.customers)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Mitarbeiter.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ customers: false })
          })
      })
    },
    updateCustomer(customer) {
      return new Promise((resolve, reject) => {
        axios
          .put(`customers/${customer.id}`, customer)
          .then(() => {
            this.updateCustomer(customer)
            resolve(customer)
          })
          .catch((error) => reject(error))
      })
    },
    deleteCustomer(customerId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`customers/${customerId}`)
          .then(() => {
            this.deleteCustomer(customerId)
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
  },
})
