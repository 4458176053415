<template>
  <div class="min-h-screen bg-gray-50">
    <Suspense>
      <RouterView />
    </Suspense>

    <div class="fixed bottom-4 right-4 z-40 space-y-4">
      <div
        v-for="alert of defaultStore.alerts"
        :key="alert.key"
        class="rounded px-6 py-4 font-medium"
        :class="{
          'bg-theme-error text-white': alert.type === 'error',
          'bg-green-600 text-white': alert.type === 'success' || !alert.type,
          'bg-orange-500 text-white': alert.type === 'warning',
        }"
      >
        {{ alert.text }}
      </div>
    </div>

    <StoreMultipleDialog />
  </div>
</template>

<script setup lang="ts">
import { useDetectNewVersion } from './composables/useDetectNewVersion'
import axios from './plugins/axios'
import { useAuthStore } from './store/auth'
import { useDefaultStore } from './store/default'

const defaultStore = useDefaultStore()
const authStore = useAuthStore()

useDetectNewVersion()

// refetch user data when focus is regained
window.addEventListener('focus', () => {
  if (!authStore.user) return

  authStore.fetchUser()
})

onMounted(() => {
  axios.get(window.location.origin + '/sanctum/csrf-cookie')
  // refetch user data every 15 minutes
  setInterval(
    () => {
      if (authStore.user) {
        authStore.fetchUser()
      }
    },
    1000 * 60 * 15 // 15minutes
  )
})
</script>

<style scoped>
.alerts {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
</style>
