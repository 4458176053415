import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'
import { getAbsences } from '@/services/absence-service'

export const useEmployeeStore = defineStore('employee', {
  state: () => ({
    absences: [] as Domain.Users.DTO.AbsenceData[],
    employeeList: [] as Domain.Users.DTO.EmployeeData[],
    employeesMeta: {},
    fetchEmployeesController: null,
  }),
  getters: {
    employees: (state) => state.employeeList.filter((e) => !e.deleted_at),
    activeEmployees: (state) => {
      return state.employeeList.filter(
        (e) => !e.deleted_at && e.user?.type === 'employee' && e.user?.is_active
      )
    },
  },
  actions: {
    fetchAbsences(params) {
      getAbsences({
        perPage: -1,
        ...params,
      }).then(({ data }) => {
        this.absences = data
      })
    },

    setEmployees(employees) {
      this.employeeList = employees.map((e) => ({
        ...e,
        nameWithCallName: `${e.name} ${e.callname || ''}`,
      }))
    },

    fetchEmployees(params) {
      const queyParams = {
        all: true,
        ...params,
      }

      if (this.fetchEmployeesController) {
        this.fetchEmployeesController.abort()
      }
      this.fetchEmployeesController = new AbortController()

      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ employees: true })
        axios
          .$get('/employees', {
            params: queyParams,
            signal: this.fetchEmployeesController.signal,
          })
          .then((response) => {
            this.setEmployees(response.data)
            resolve(this.employees)
          })
          .catch((error) => {
            if (error.message === 'canceled') return
            useDefaultStore().error('Fehler beim Laden der Mitarbeiter.')
            reject(error)
          })
          .finally(() => {
            this.fetchEmployeesController = null
            useLoadingStore().loading({ employees: false })
          })
      })
    },
  },
})
