import { type Router } from 'vue-router'

let cumulativeQuery = {}

/**
 * When multiple query updates are made in the same tick, we want to batch them.
 * Otherwise, not all updates will be reflected in the URL.
 */
export function sheduleQueryUpdate(query: Record<string, any>, router: Router) {
  if (Object.keys(cumulativeQuery).length === 0) {
    nextTick(() => {
      router.replace({
        query: { ...router.currentRoute.value.query, ...cumulativeQuery },
      })
      cumulativeQuery = {}
    })
  }

  cumulativeQuery = { ...cumulativeQuery, ...query }
}
