import axios from 'axios'
import { UserType } from '@/utils'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'

export const useUserStore = defineStore('user', {
  state: () => ({
    users: [],
  }),
  getters: {
    activeUsers: (state) => state.users.filter((u) => u.is_active),
    employeesAndWorkers: (state) =>
      state.users.filter(
        (u) => u.type === UserType.Worker || u.type === UserType.Employee
      ),
  },
  actions: {
    fetchUsers(params) {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ users: true })
        axios
          .$get('/users', {
            params: {
              ...params,
            },
          })
          .then((data) => {
            this.users = data
            resolve(this.users)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Mitarbeiter.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ users: false })
          })
      })
    },
  },
})
