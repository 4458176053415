import { SUPPORT_LOCALES } from './i18n'

export function getLocale() {
  const locale = localStorage.getItem('locale')
  if (locale) {
    return locale
  }

  const browserLocale = getBrowserLocale()

  return SUPPORT_LOCALES.includes(browserLocale) ? browserLocale : 'de'
}

export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: true }
  const opt = { ...defaultOptions, ...options }
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language
  if (!navigatorLocale) {
    return undefined
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()

  return trimmedLocale
}
