import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/kunden-registration',
    name: 'CustomerRegistration',
    component: () => import('../Views/Forms/CustomerRegistration.vue'),
    meta: { auth: 'optional' },
  },
  {
    path: 'forms/customer-registration',
    name: 'SubmittedCustomerRegistrations',
    component: () =>
      import('../Views/Forms/SubmittedCustomerRegistrations.vue'),
    meta: { auth: true },
  },
  {
    path: '/saison-anmeldung',
    name: 'SeasonApplication',
    component: () => import('../Views/Forms/SeasonApplication.vue'),
    meta: { auth: 'optional' },
  },
  {
    path: '/personalien-arbeitnehmer',
    name: 'EmployeeRegistration',
    component: () => import('../Views/Forms/EmployeeRegistration.vue'),
  },
  {
    path: '/zahlungsverbindung-fremdes-konto',
    name: 'ForeignPaymentConnection',
    component: () => import('../Views/Forms/PaymentConnection.vue'),
    props: { type: 'foreign' },
  },
  {
    path: '/zahlungsverbindung',
    name: 'PaymentConnection',
    component: () => import('../Views/Forms/PaymentConnection.vue'),
    props: { type: 'self' },
  },
  {
    path: '/settings/forms',
    name: 'FromsSettings',
    component: () => import('../Views/Forms/FormsSettings.vue'),
    meta: { auth: true },
  },
  {
    path: '/forms/season-application',
    name: 'SubmittedSeasonApplications',
    component: () => import('../Views/Forms/SubmittedSeasonApplications.vue'),
    meta: { auth: true },
  },
  {
    path: '/forms/employee-registration',
    name: 'SubmittedEmployeeRegistrations',
    component: () =>
      import('../Views/Forms/SubmittedEmployeeRegistrations.vue'),
    meta: { auth: true },
  },

  {
    path: '/forms/foreign-payment-connection',
    name: 'SubmittedForeignPaymentConnections',
    component: () => import('../Views/Forms/SubmittedPaymentConnections.vue'),
    meta: { auth: true },
    props: {
      type: 'foreign',
    },
  },

  {
    path: '/forms/payment-connection',
    name: 'SubmittedPaymentConnections',
    component: () => import('../Views/Forms/SubmittedPaymentConnections.vue'),
    meta: { auth: true },
    props: {
      type: 'self',
    },
  },
  {
    path: '/forms/employee-season-papers',
    name: 'SubmittedEmployeeSeasonPapers',
    component: () => import('../Views/Forms/SubmittedEmployeeSeasonPapers.vue'),
    meta: { auth: true },
  },
  {
    path: '/personalienblatt-arbeitnehmer',
    name: 'EmployeeSeasonPaper',
    component: () => import('../Views/Forms/EmployeeSeasonPaper.vue'),
    meta: { auth: true },
  },

  {
    path: '/forms/runsheet/:slug',
    name: 'SubmittedRunSheets',
    component: () => import('../Views/RunSheets/SubmittedRunSheets.vue'),
    meta: { auth: true },
    props: (route) => ({ slug: route.params.slug }),
  },
  {
    path: '/forms/runsheet/:slug/submit',
    name: 'SubmitRunSheet',
    component: () => import('../Views/RunSheets/SubmitRunSheet.vue'),
    meta: { auth: true },
    props: (route) => ({ slug: route.params.slug }),
  },
  {
    path: '/forms/runsheet/edit/:id',
    name: 'EditRunSheet',
    component: () => import('../Views/RunSheets/EditRunSheet.vue'),
    meta: { auth: true },
    props: (route) => ({ id: route.params.id }),
  },

  {
    path: '/settings/run-sheet-templates',
    name: 'RunSheetTemplates',
    component: () => import('../Views/Settings/RunSheetTemplates.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/run-sheet-templates/create',
    name: 'CreateRunSheetTemplate',
    component: () => import('../Views/Settings/CreateRunSheetTemplate.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/run-sheet-templates/edit/:id',
    name: 'EditRunSheetTemplate',
    component: () => import('../Views/Settings/EditRunSheetTemplate.vue'),
    meta: { auth: true },
  },
]
