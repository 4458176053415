import posthog from 'posthog-js'

export default {
  install(app) {
    if (
      process.env.NODE_ENV === 'development' ||
      import.meta.env.VITE_DISABLE_POSTHOG === 'true'
    ) {
      return
    }

    app.config.globalProperties.$posthog = posthog.init(
      'phc_BhH5CI9m9MOnb4DapBvtwhny4Dffc8jFuVnn8yqg8ut',
      {
        api_host: 'https://app.posthog.com',
      }
    )

    app.provide('posthog', posthog)
  },
}
