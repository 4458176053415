import axios from 'axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'

export const useTransactionTypeStore = defineStore('transactionType', {
  state: () => ({
    allTransactionTypes: [],
  }),
  getters: {
    transactionTypes: (state) =>
      state.allTransactionTypes.filter((t) => !t.deleted_at),
    deletedTransactionTypes: (state) =>
      state.allTransactionTypes.filter((t) => t.deleted_at),
  },
  actions: {
    setTransactionTypes(transactionTypes) {
      this.allTransactionTypes = transactionTypes
    },
    fetchTransactionTypes() {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ transactionTypes: true })
        axios
          .get('/transaction-types?all=true')
          .then((response) => {
            this.allTransactionTypes = response.data.data
            resolve(this.transactionTypes)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Betten.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ transactionTypes: false })
          })
      })
    },
  },
})
