import axios from '@/plugins/axios'
import { useDefaultStore } from '@/store/default'

import {
  createLoadingCall,
  createSavingCall,
  HandledError,
} from './base-service'

// Run Sheets
export const getRunSheet = createLoadingCall(
  'runSheets',
  (idOrSlug, params = {}) =>
    axios.$get(`run-sheets/${idOrSlug}`, {
      params,
    })
)

export const getRunSheets = createLoadingCall('runSheets', (params = {}) =>
  axios.$post('run-sheets/search', params)
)

export const deleteRunSheet = createSavingCall((id) =>
  axios.$delete(`run-sheets/${id}`)
)

// Templates
export const getRunSheetTemplates = createLoadingCall(
  'runSheetTemplates',
  (params = {}) => axios.$get('run-sheet-templates', { params })
)

export const getRunSheetTemplate = createLoadingCall(
  'runSheetTemplate',
  (idOrSlug, params = {}) =>
    axios.$get(`run-sheet-templates/${idOrSlug}`, { params })
)

export const createRunSheetTemplate = createSavingCall((data) =>
  axios.$post('run-sheet-templates', data)
)

export const updateRunSheetTemplate = createSavingCall((id, data) => {
  {
    if (data.id) {
      return axios.$put(`run-sheet-templates/${id}`, data)
    } else {
      return axios.$patch(`run-sheet-templates/${id}`, data)
    }
  }
})

export const deleteRunSheetTemplate = createSavingCall(async (id) => {
  try {
    await axios.$delete(`run-sheet-templates/${id}`)
  } catch (error) {
    if (
      error.includes(
        'Cannot delete run sheet template with run sheets attached to it'
      )
    ) {
      useDefaultStore().alert({
        type: 'warning',
        text: 'Kann nicht gelöscht werden, da noch Laufblätter vorhanden sind.',
        duration: 5,
      })
      throw new HandledError(error.message)
    }

    throw error
  }
})
