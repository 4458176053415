import moment from 'moment'

export type Dateable = string | Date | moment.Moment

/**
 * Creates an array of dates between the start and end date.
 *
 * e.g. createDatesArray('2022-01-01', '2022-01-03') => ['2022-01-01', '2022-01-02', '2022-01-03']
 */
export function createDatesArray(
  startDate: Dateable,
  endDate?: Dateable | null
) {
  const _startDate = moment(startDate)
  const _endDate = moment(endDate ?? startDate)

  const dates: string[] = []
  while (_startDate.isSameOrBefore(_endDate, 'day')) {
    dates.push(_startDate.format('YYYY-MM-DD'))
    _startDate.add(1, 'day')
  }

  return dates
}
