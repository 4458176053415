import type { DirectiveBinding } from 'vue'

type SwipeEvent = (value: {
  direction: 'left' | 'right'
  deltaX: number
}) => void

export const swipe = {
  mounted(el: HTMLElement, binding: DirectiveBinding<SwipeEvent>) {
    let startX: number, startY: number

    function handleMove(event: TouchEvent) {
      const touch = event.changedTouches[0]
      const deltaX = touch.clientX - startX
      const deltaY = touch.clientY - startY

      if (
        Math.abs(deltaX) < Math.abs(deltaY) ||
        Math.abs(deltaX) < 50 ||
        Math.abs(deltaY) > 50
      )
        return
      if (deltaX > 50 && (binding.arg === 'right' || !binding.arg)) {
        binding.value({
          direction: 'right',
          deltaX,
        })
      } else if (deltaX < -50 && (binding.arg === 'left' || !binding.arg)) {
        binding.value({
          direction: 'left',
          deltaX,
        })
      }
    }

    function touchstart(event: TouchEvent) {
      const touch = event.touches[0]
      startX = touch.clientX
      startY = touch.clientY
    }
    el.addEventListener('touchstart', touchstart, { passive: true })

    function touchmove(event: TouchEvent) {
      if (
        !event.changedTouches ||
        !event.changedTouches.length ||
        binding.modifiers.end
      ) {
        return
      }

      handleMove(event)
    }
    el.addEventListener('touchmove', touchmove, { passive: true })

    function touchend(event: TouchEvent) {
      if (
        !event.changedTouches ||
        !event.changedTouches.length ||
        !binding.modifiers.end
      ) {
        return
      }

      handleMove(event)
    }
    el.addEventListener('touchend', touchend, false)
  },
}
