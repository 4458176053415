<template>
  <Component
    :is="component"
    v-bind="linkAttributes"
    :type="type"
    :disabled="disabled || loading"
    :class="
      cn(
        'inline-flex cursor-pointer items-center justify-center gap-2 transition-all duration-300 disabled:pointer-events-none disabled:opacity-50',
        tailwindTextColor,
        tailwindBackgroundColor,
        tailwindPadding,
        {
          rounded: !icon,
          'text-sm': !icon && size === 'xs',
          'font-medium':
            !icon && (size === 'md' || size === 'lg' || size === 'sm'),
          'h-6 w-6': icon && size === 'xs',
          'h-7 w-7': icon && size === 'sm',
          'h-10 w-10': icon && size === 'md',
          'h-12 w-12': icon && size === 'lg',
          'rounded-full': icon,
          'text-opacity-0 [&>*:not(.spinner)]:opacity-0': loading,
        },
        props.class
      )
    "
  >
    <slot></slot>

    <AppIcon v-if="icon && typeof icon === 'string'" :size="size">
      {{ icon }}
    </AppIcon>

    <div
      v-if="loading"
      class="spinner absolute flex w-0 items-center justify-center overflow-visible text-opacity-100"
      :class="[tailwindTextColor]"
    >
      <Spinner class="shrink-0 text-lg" />
    </div>
  </Component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink, type RouterLinkProps } from 'vue-router'
import { cn } from '@/utils/css-helpers'

export interface Props {
  /**
   * Google Material Icon name. Or true and pass AppIcon component as a child.
   * Often has to be combined with `text` and `color="black"` to make it look good.
   * Example: <AppButton icon="add" text color="black" />
   */
  icon?: boolean | string | null //dsjflka
  /** If true, the button will be rendered as a text button */
  text?: boolean
  /** Router Link or absolute URL */
  to?: null | string | RouterLinkProps['to']
  /** Html button type */
  type?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  color?: 'primary' | 'white' | 'black' | 'red' | 'orange' | 'gray'
  /** If true, the button will be rendered as a loading button (text will be hidden) */
  loading?: boolean
  disabled?: boolean
  outlined?: boolean
  dense?: boolean
  class?: string
}

const props = withDefaults(defineProps<Props>(), {
  icon: null,
  text: false,
  to: null,
  type: 'button',
  size: 'md',
  color: 'primary',
  loading: false,
  disabled: false,
  class: '',
})

const tailwindTextColor = computed(() => {
  switch (props.color) {
    case 'gray':
      return props.text || props.outlined
        ? 'text-gray-500 hover:text-gray-600'
        : 'text-black'
    case 'primary':
      return props.text || props.outlined
        ? 'text-theme-primary hover:text-theme-primary-hover'
        : 'text-white'

    case 'white':
      return props.text || props.outlined
        ? 'text-white hover:text-gray-300 dark:text-black dark:hover:text-gray-700'
        : 'text-black dark:text-white'

    case 'black':
      return props.text || props.outlined
        ? 'text-black hover:text-gray-700 dark:text-white dark:hover:text-gray-300'
        : 'text-white dark:text-black'

    case 'red':
      return props.text || props.outlined
        ? 'text-red-500 hover:text-red-700'
        : 'text-white'

    case 'orange':
      return props.text || props.outlined
        ? 'text-orange-500 hover:text-orange-700'
        : 'text-white'

    default:
      return ''
  }
})

const tailwindBackgroundColor = computed(() => {
  if (props.text) {
    return 'bg-transparent hover:bg-gray-500/30 dark:hover:bg-gray-400/30'
  }
  if (props.outlined) {
    switch (props.color) {
      case 'primary':
        return 'bg-transparent border border-theme-primary hover:bg-theme-primary/10'
      case 'white':
        return 'bg-transparent border border-white hover:bg-white/10'
      case 'black':
        return 'bg-transparent border border-black hover:bg-black/10'
      case 'red':
        return 'bg-transparent border border-red-500 hover:bg-red-500/10'
      case 'orange':
        return 'bg-transparent border border-orange-500 hover:bg-orange-500/10'
      case 'gray':
        return 'bg-transparent border border-gray-400 hover:bg-gray-500/10'
      default:
        return null
    }
  }

  switch (props.color) {
    case 'primary':
      return 'bg-theme-primary hover:bg-theme-primary-hover'
    case 'white':
      return 'bg-white hover:bg-gray-200'
    case 'black':
      return 'bg-black hover:bg-gray-600'
    case 'red':
      return 'bg-red-500 hover:bg-red-600'
    case 'orange':
      return 'bg-orange-500 hover:bg-orange-700'
    case 'gray':
      return 'bg-gray-100 hover:bg-gray-300'
    default:
      return null
  }
})

const tailwindPadding = computed(() => {
  if (props.icon) {
    return 'px-0 py-0'
  }

  if (props.dense) {
    return 'px-1.5 py-0.5'
  }

  switch (props.size) {
    case 'xs':
      return 'px-2 py-1'
    case 'sm':
      return 'px-3 py-[0.3rem]'
    case 'md':
      return 'px-4 py-2'
    case 'lg':
      return 'px-6 py-3'
    default:
      return null
  }
})

const isExternalUrl = computed(() => {
  return props.to && typeof props.to === 'string' && props.to.startsWith('http')
})

const component = computed(() => {
  if (isExternalUrl.value) {
    return 'a'
  }

  if (props.to) {
    return RouterLink
  }

  return 'button'
})

const linkAttributes = computed(() => {
  if (isExternalUrl.value) {
    return {
      href: props.to,
    }
  }
  if (props.to) {
    return {
      to: props.to,
    }
  }

  return {}
})
</script>
