import axios from '@/plugins/axios'
import { t } from '@/plugins/i18n'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'

export const useToolStore = defineStore('tool', {
  state: () => ({
    allTools: [] as Domain.ResourcePlanner.DTO.ToolData[],
  }),
  getters: {
    tools: (state) => state.allTools.filter((r) => !r.deleted_at),
  },
  actions: {
    setTools(tools) {
      this.allTools = tools
    },
    addTool(tool) {
      this.allTools.push(tool)
    },
    removeTool(toolId) {
      const tool = this.allTools.find((t) => t.id === toolId)
      const index = this.allTools.indexOf(tool)
      this.allTools.splice(index, 1)
    },
    async fetchTools(params = {}) {
      useLoadingStore().loading({ tools: true })

      const { data } = await axios
        .get('/tools', {
          params,
        })
        .catch((error) => {
          useDefaultStore().alert({
            text: t('fehler-beim-laden-der-werkzeuge'),
            type: 'error',
          })
          throw error
        })
        .finally(() => {
          useLoadingStore().loading({ tools: false })
        })

      this.setTools(data.data)
      return this.tools
    },
    async updateTool(tool) {
      const formData = new FormData()
      for (const key of Object.keys(tool)) {
        formData.append(key, tool[key] || '')
      }

      const { data } = await axios.$post(`tools/${tool.id}`, formData)

      const oldTool = this.allTools.find((r) => r.id === data.id)
      const index = this.allTools.indexOf(oldTool)
      this.allTools[index] = data
      this.allTools = [...this.allTools]
      return data
    },
    async createTool(tool) {
      const formData = new FormData()
      for (const key of Object.keys(tool)) {
        formData.append(key, tool[key] || '')
      }

      const { data } = await axios.post('tools', formData)

      this.fetchTools()
      return data.data
    },
    async deleteTool(toolId) {
      await axios.delete(`tools/${toolId}`)
      this.removeTool(toolId)
    },
  },
})
