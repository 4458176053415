import { COLORS } from '@/constants'
import Swal, {
  type SweetAlertOptions,
  type SweetAlertResult,
} from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { t } from '@/plugins/i18n'

export function swal(
  optionsOrTitle: SweetAlertOptions | string,
  text: string = '',
  icon: SweetAlertOptions['icon'] = 'success'
) {
  if (typeof optionsOrTitle === 'string') {
    return Swal.fire({
      title: optionsOrTitle,
      text,
      icon,
      confirmButtonColor: COLORS.PRIMARY,
    })
  }

  return Swal.fire({
    confirmButtonColor: COLORS.PRIMARY,
    ...optionsOrTitle,
  })
}

export function confirmAction(
  text?: string,
  confirmButtonText?: string,
  title?: string
): Promise<string>
export function confirmAction(
  options: SweetAlertOptions
): Promise<SweetAlertResult>

export function confirmAction(
  text: string | SweetAlertOptions = t('general.confirm-action.text'),
  confirmButtonText = t('general.confirm-action.yes-delete'),
  title = t('general.confirm-action.title')
): Promise<string | SweetAlertResult> {
  const defaultOptions = {
    title: t('general.confirm-action.title'),
    text: t('general.confirm-action.text'),
    confirmButtonText: t('general.confirm-action.yes-delete'),
    cancelButtonText: t('general.confirm-action.no-cancel'),
    showCancelButton: true,
    icon: 'warning',
  }

  let options
  if (typeof text === 'object') {
    options = {
      ...defaultOptions,
      ...text,
    }
  } else {
    options = {
      ...defaultOptions,
      text,
      confirmButtonText,
      title,
    }
  }

  return new Promise((resolve) => {
    swal(options).then((result) => {
      if (typeof text === 'object') {
        resolve(result)
      } else {
        resolve(result.value)
      }
    })
  })
}
