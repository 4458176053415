import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/customer',
    name: 'Customers',
    component: () => import('../Views/Customer/Customers.vue'),
    meta: { auth: true },
  },
  {
    path: '/customer/add',
    name: 'AddCustomer',
    component: () => import('../Views/Customer/AddCustomer.vue'),
    meta: { auth: true },
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: () => import('../Views/Customer/Customer.vue'),
    meta: { auth: true },
  },
  {
    path: '/customers/:id/hourrecords',
    name: 'CustomerHourrecords',
    component: () => import('../Views/Hourrecords/CustomerHourrecords.vue'),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/family-allowances',
    name: 'FamilyAllowances',
    component: () => import('../Views/FamilyAllowance/FamilyAllowances.vue'),
    meta: { auth: true },
  },
  {
    path: '/absences',
    name: 'AbsencePlanner',
    component: () => import('../Views/AbsencePlanner/AbsencePlanner.vue'),
    meta: { auth: true },
  },
  {
    path: '/employee',
    name: 'Employees',
    component: () => import('../Views/Employee/Employees.vue'),
    meta: { auth: true },
  },
  {
    path: '/guests',
    name: 'Guests',
    component: () => import('../Views/Employee/Guests.vue'),
    meta: { auth: true },
  },
  {
    path: '/user/add',
    name: 'AddUser',
    component: () => import('../Views/User/AddUser.vue'),
    meta: { auth: true },
  },
  {
    path: '/user/:id',
    name: 'EditUser',
    component: () => import('../Views/User/EditUser.vue'),
    meta: { auth: true },
  },
  {
    path: '/worker',
    name: 'Workers',
    component: () => import('../Views/Worker/Workers.vue'),
    meta: { auth: true },
  },
  {
    path: '/arrival-departure-manager',
    name: 'WorkingContracts',
    component: () => import('../Views/WorkingContracts/WorkingContracts.vue'),
    meta: { auth: true },
  },
  {
    path: '/ferien-und-ueberstunden',
    name: 'HolidaysAndOvertime',
    component: () =>
      import('../Views/WorkingContracts/HolidaysAndOvertime.vue'),
    meta: { auth: true },
  },
  {
    path: '/users/merge',
    name: 'MergeUsers',
    component: () => import('../Views/User/MergeUsers.vue'),
    meta: { auth: true },
  },
  {
    path: '/company-phones',
    name: 'CompanyPhones',
    component: () => import('../Views/User/CompanyPhone/CompanyPhones.vue'),
    meta: { auth: true },
  },
  {
    path: '/create-company-phone',
    name: 'CreateCompanyPhone',
    component: () =>
      import('../Views/User/CompanyPhone/CreateCompanyPhone.vue'),
    meta: { auth: true },
  },
  {
    path: '/company-phones/:id',
    name: 'EditCompanyPhone',
    component: () => import('../Views/User/CompanyPhone/EditCompanyPhone.vue'),
    meta: { auth: true },
    props: true,
  },
]
