import { pageRect, positioningRelativeTo } from '@/utils/positioning'
import type { DirectiveBinding, ObjectDirective } from 'vue'

let tooltipEl: HTMLDivElement | null = null

function showTooltip(el: HTMLElement, binding: DirectiveBinding<string>) {
  if (tooltipEl) {
    hideTooltip()
  }

  if (!binding.value) return

  tooltipEl = document.createElement('div')
  tooltipEl.className =
    'absolute z-30 max-w-[calc(100vw-20px)] rounded bg-gray-600/80 px-3 py-1 text-white'

  tooltipEl.style.top = '0px'
  tooltipEl.style.left = '0px'
  tooltipEl.style.visibility = 'hidden'
  tooltipEl.textContent = binding.value
  document.body.appendChild(tooltipEl)

  nextTick(() => {
    if (!tooltipEl) return

    const hoverRect = pageRect(el)
    const tooltipRect = pageRect(tooltipEl)

    let orientation = 'top' as 'top' | 'left' | 'right' | 'bottom'
    if (binding.modifiers.left) orientation = 'left'
    if (binding.modifiers.right) orientation = 'right'
    if (binding.modifiers.bottom) orientation = 'bottom'
    if (binding.modifiers.top) orientation = 'top'

    const position = positioningRelativeTo(orientation, hoverRect, tooltipRect)

    tooltipEl.style.visibility = 'visible'
    tooltipEl.style.top = `${position.top}px`
    tooltipEl.style.left = `${position.left}px`
  })
}

function hideTooltip() {
  if (tooltipEl) {
    document.body.removeChild(tooltipEl)
    tooltipEl = null
  }
}

interface TooltipElement extends HTMLElement {
  _showTooltip?: () => void
  _hideTooltip?: () => void
}

function removeTooltip(el: TooltipElement) {
  hideTooltip()

  if (el._showTooltip) {
    el.removeEventListener('mouseenter', el._showTooltip)
    el.removeEventListener('hover', el._showTooltip)
    el.removeEventListener('focus', el._showTooltip)
  }
  if (el._hideTooltip) {
    el.removeEventListener('mouseleave', el._hideTooltip)
    el.removeEventListener('blur', el._hideTooltip)
  }
}

function setupTooltip(el: TooltipElement, binding: DirectiveBinding<string>) {
  removeTooltip(el)

  el._showTooltip = () => showTooltip(el, binding)
  el.addEventListener('mouseenter', el._showTooltip)
  el.addEventListener('hover', el._showTooltip)
  el.addEventListener('focus', el._showTooltip)

  el._hideTooltip = () => hideTooltip()
  el.addEventListener('mouseleave', hideTooltip)
  el.addEventListener('blur', hideTooltip)
}

export const tooltip: ObjectDirective<TooltipElement, string> = {
  mounted: (el: HTMLElement, binding: DirectiveBinding<string>) => {
    setupTooltip(el, binding)
  },

  updated(el: HTMLElement, binding: DirectiveBinding<string>) {
    setupTooltip(el, binding)
  },

  beforeUnmount(el: HTMLElement) {
    removeTooltip(el)
  },
}
