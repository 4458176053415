import { defineStore } from 'pinia'

export const useStoreMultipleStore = defineStore('storeMultiple', {
  state: () => ({
    toStore: [],
    itemTextFunction: (item) =>
      item.name || item.text || item.title || item.label,
    toStoreOptions: {},
  }),
  getters: {
    toStoreFinished: (state) =>
      state.toStore.length &&
      state.toStore.every((item) => item.status.key !== 'loading'),
    toStoreSucceeded: (state) =>
      state.toStore.every((item) => item.status.key === 'success'),
  },
  actions: {
    setToStoreItems(toStoreItems) {
      this.toStore = toStoreItems.map((item) => ({
        item,
        status: {
          key: 'loading',
          message: null,
        },
      }))
    },
    setItemTextFunction: (callback) => (this.itemTextFunction = callback),
    setToStoreOptions: (options) => (this.toStoreOptions = options),
    setToStoreStatus({ item, status }) {
      const toStoreItems = [...this.toStore]
      const toStore = toStoreItems.find((i) => i.item === item)
      const index = toStoreItems.indexOf(toStore)
      toStoreItems[index].status = status
      this.toStore = toStoreItems
    },
  },
})
