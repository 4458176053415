import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/kundenportal',
    name: 'CustomerPortal',
    children: [
      {
        path: '/kundenportal',
        name: 'CustomerLandingPage',
        component: () =>
          import('../Views/CustomerPortal/CustomerLandingPage.vue'),
        meta: { auth: true },
      },
      {
        path: 'auftraege',
        name: 'OrderOverview',
        component: () => import('../Views/CustomerPortal/OrderOverview.vue'),
        meta: { auth: true },
      },
      {
        path: 'auftraege/erfassen',
        name: 'CreateOrder',
        component: () =>
          import('../Views/CustomerPortal/Orders/CreateOrder.vue'),
        meta: { auth: true },
      },
      {
        path: 'auftraege/:id/bearbeiten',
        name: 'EditOrder',
        component: () => import('../Views/CustomerPortal/Orders/EditOrder.vue'),
        meta: { auth: true },
      },
      {
        path: '/auftraege/:id/karte',
        alias: '/orders/:id/map',
        name: 'OrderMap',
        component: () => import('../Views/CustomerPortal/Orders/OrderMap.vue'),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'auftraege/:id',
        name: 'ShowOrder',
        component: () => import('../Views/CustomerPortal/Orders/ShowOrder.vue'),
        meta: { auth: true },
      },
      {
        path: 'felder',
        name: 'ArableFieldOverview',
        component: () =>
          import(
            '../Views/CustomerPortal/ArableFields/ArableFieldOverview.vue'
          ),
        meta: { auth: true },
      },
      {
        path: 'profil',
        name: 'Profile',
        component: () => import('../Views/CustomerPortal/EditProfile.vue'),
        meta: { auth: true },
      },
      {
        path: 'erfassen',
        name: 'WorkRecord',
        component: () => import('../Views/CustomerPortal/WorkRecord.vue'),
        meta: { auth: true },
      },
      {
        path: 'erfassen/details',
        name: 'WorkRecordDetails',
        component: () =>
          import('../Views/CustomerPortal/WorkRecordDetails.vue'),
        meta: { auth: true },
      },
      {
        path: 'wochenrapport',
        name: 'CustomerRapportOverview',
        component: () =>
          import('../Views/CustomerPortal/Rapports/WeekRapports.vue'),
        meta: { auth: true },
      },
      {
        path: 'wochenrapport/:id',
        name: 'CustomerRapport',
        component: () => import('../Views/CustomerPortal/Rapports/Rapport.vue'),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'faqs',
        name: 'CustomePortalFAQ',
        component: () => import('../Views/FAQ/ViewFaqs.vue'),
        meta: { auth: true },
      },
      {
        path: 'kontakt',
        name: 'contact',
        component: () => import('../Views/CustomerPortal/Contact.vue'),
        meta: { auth: true },
      },
    ],
  },
]
