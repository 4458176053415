import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: {
      roles: false,
      rooms: false,
      beds: false,
      employees: false,
      customers: false,
      workers: false,
      projects: false,
      authorizationRules: false,
      dashboard: false,
      evaluation: false,
      worktypes: false,
      settings: false,
      transactionTypes: false,
      transactions: false,
      tools: false,
      cars: false,
      warningCategories: false,
      resources: false,
      languages: false,
      absences: false,
      plannerDay: false,
      files: false,
    },
  }),
  actions: {
    loading(isLoading) {
      for (const key in isLoading) {
        this.isLoading[key] = isLoading[key]
      }
    },
  },
})
