import axios from 'axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import { useDefaultStore } from './default'

export const useTransactionStore = defineStore('transaction', {
  state: () => ({
    transactions: [],
    transactionsMeta: {},
  }),
  actions: {
    fetchTransactions(pagination) {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ transactions: true })
        axios
          .$post('transactions/search', pagination)
          .then((response) => {
            this.transactions = response.data
            this.transactionsMeta = response.meta || {}
            resolve(this.transactions)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Vorschüsse.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ transactions: false })
          })
      })
    },
  },
})
