import { normalizeClass } from 'vue'
import { twMerge } from 'tailwind-merge'

export function cn(...classes: ClassValue[]) {
  return twMerge(normalizeClass(classes))
}

type ClassDictrionary = Record<string, any>
type ClassArray = ClassValue[]
type ClassValue =
  | ClassArray
  | ClassDictrionary
  | string
  | null
  | boolean
  | undefined
